<template>
  <form class="reward-container" @submit.prevent="onSubmit">

    <RewardGemsEarnedLayout
      :gems="earnedGems"
      :subDescription="$tc('rewards.modals.gems_kyc.sub_description')"
      :subTitle="$tc('rewards.modals.gems_kyc.sub_title')"
      :description="$tc('rewards.modals.gems_kyc.description', earnedGems, { gems: earnedGems })"
    />

    <ButtonV2
      data-cy="button-submit"
      data-testid="button-submit"
      :label="$t('continue')"
      size="medium"
      type="submit"
      variation="primary"
      wide
    />

  </form>
</template>

<script>
import { mapActions } from 'vuex';

import ButtonV2 from '@/stories/misc/ButtonV2';
import RewardGemsEarnedLayout from '@/modules/rewards/modals/RewardGemsEarned/layout.vue';

import { formatDecimals } from '@/utils/formatters';

export default {
  name: 'RewardGemsKyc',
  components: {
    ButtonV2,
    RewardGemsEarnedLayout
  },
  props: {
    gems: {
      type: String,
      required: true,
    }
  },
  mounted() {
    this.setModalProps({
      isClosable: false,
    });
  },
  computed: {
    earnedGems() {
      return formatDecimals(this.gems);
    }
  },
  methods: {
    ...mapActions('ui', [
      'setModalProps'
    ]),

    onSubmit() {
      this.showModal('KycCountry');
    },
  },
};
</script>

<style scoped>
.reward-container {
  @apply flex flex-col gap-s24 mb-s20;
  max-width: 408px;
}
</style>
